<template>
  <div id="schedule_table">
    <!-- スケジュール表のマスのテンプレート -->
    <div id="cell_template" class="d-none">
      <div class="date_cell" schedule_id="">
        <div class="start_time font-weight-bold"></div>
        <span class="sp"></span>
        <div class="end_time font-weight-bold"></div>
      </div>
    </div>

    <!-- スケジュール表 -->
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      スケジュールは毎朝6時半～7時頃、媒体に自動更新します。尚「AUTO設定」をONにすると1週間先までのパターンが自動更新前に適用されます。
    </div>
    <div
      v-show="isEsutamaComment"
      class="text-subtitle-2 pa-1 orange--text orange lighten-5"
    >
      【専用機能】エステ魂のスケジュールを自動更新する場合は「エステ魂自動設定」ボタンにて設定してください。
    </div>
    <div class="icon-description-container">
      <div class="text-subtitle-2 pa-1 d-flex nowrap">
        <span class="d-flex align-center">【アイコン説明】</span>
        <span class="d-flex align-center mr-4"
          ><v-icon class="mr-1" small>mdi-trash-can</v-icon>シフト削除</span
        >
        <span class="d-flex align-center">
          <v-icon class="mr-1" small>mdi-arrow-right-bold</v-icon
          >翌日にコピーする</span
        >
      </div>
    </div>
    <v-app-bar flat dense color="white">
      <div class="sp_schedule_label align-center">
        <v-icon small>mdi-cursor-pointer</v-icon
        >マス目をスワイプして横スクロール
      </div>
      <div class="pc_schedule_label align-center">
        <v-icon>mdi-mouse-left-click</v-icon>マス目をドラッグして横スクロール
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        id="prev_btn"
        :disabled="!weekBtn.prev"
        @click="onPrev"
        class="mr-3"
      >
        <v-icon>mdi-chevron-double-left</v-icon>
        <div>前週</div>
      </v-btn>
      <v-btn
        icon
        id="today_btn"
        :disabled="!weekBtn.today"
        @click="onToday"
        class="mr-3"
      >
        <div>今日</div>
      </v-btn>
      <v-btn icon id="next_btn" :disabled="!weekBtn.next" @click="onNext">
        <div>次週</div>
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>
    </v-app-bar>
    <div class="cast_area" sticky-container>
      <!-- キャスト列ヘッダー -->
      <div class="schedule_table_upper_header">
        在席:&nbsp;{{ casts.length }}&nbsp;人
      </div>
      <template v-for="(cast, index) in casts">
        <div
          v-if="index % 8 == 0"
          class="schedule_table_header center_item"
          :key="cast.id + 'header'"
        >
          キャスト名
        </div>
        <!-- キャスト情報 ここから -->
        <div :key="cast.id">
          <div class="cast_name">
            <div class="cast_name_box">
              <v-avatar class="ma-1 mr-1"
                ><v-img v-if="cast.image" :src="setting.cdn + cast.image" />
                <v-img v-else src="@/assets/images/no_image.jpg" />
              </v-avatar>
              <div class="cast_name_label text-truncate">
                <span v-if="cast.is_enable" class="mini text-blue"
                  >[公開]<br
                /></span>
                <span v-else class="mini text-gray">[非公開]<br /></span>
                <span :class="`${cast.is_enable ? 'font-bold' : ''}`">{{
                  cast.name
                }}</span>
              </div>
            </div>
          </div>
          <a
            @click="showPatternDialog(index)"
            class="cast_name_bottom d-flex align-center justify-center"
          >
            <div>
              <div
                v-if="casts_schedule__is_auto[cast.id]"
                class="cast_auto_label text-truncate d-flex align-center"
              >
                <v-icon small color="#1976d2" class="mr-2"
                  >mdi-autorenew</v-icon
                >
                AUTO設定中
              </div>
              <div
                v-else
                class="cast_auto_label cast_auto_label_disable text-truncate d-flex align-center"
              >
                <v-icon small color="#bbb" class="mr-2">mdi-autorenew</v-icon>
                AUTO未設定
              </div>
            </div>
          </a>
        </div>
        <!-- キャスト情報 ここまで -->
      </template>
    </div>
    <div class="swiper_area">
      <swiper :options="swiperOptions">
        <!-- 日付列ヘッダー -->
        <swiper-slide v-for="[key, value] in Object.entries(week)" :key="key">
          <div
            :class="
              value.work_num == 0
                ? 'backcolor_gray schedule_table_upper_header'
                : 'schedule_table_upper_header'
            "
          >
            出勤:&nbsp;{{ value.work_num }}&nbsp;人
          </div>
          <template v-for="(cast, index) in casts">
            <!-- 日付情報 ここから -->
            <div
              v-if="index % 8 == 0"
              class="schedule_table_header center_item"
              :key="cast.id + '_' + value.long_date"
            >
              {{ value.short_date }}
            </div>
            <div :key="cast.id + cast.selected_datetime_number">
              <div
                :class="
                  'sc_cell center_item ' +
                  `${'schedule_' + cast.id + '_' + value.class_date}` +
                  ' ' +
                  `${cast[value.long_date].cell_color}` +
                  ' ' +
                  `${cast[value.long_date].no_pattern_update}`
                "
              >
                <!-- シフトを表示 -->
                <template
                  v-if="
                    cast[value.long_date].start_time != null ||
                    cast[value.long_date].end_time != null
                  "
                >
                  <div
                    class="date_cell d-flex justify-center align-center"
                    :schedule_id="cast[value.long_date].id"
                    :config="cast[value.long_date].config"
                    @click="
                      onCell(
                        value.long_date,
                        value.short_date,
                        'schedule_' + cast.id + '_' + value.class_date,
                        cast.id
                      )
                    "
                  >
                    <!-- <div>
                      <span class="start_time font-weight-bold">
                        {{ cast[value.long_date].start_time }}
                      </span>
                      <span class="sp">&nbsp;-&nbsp;</span>
                      <span class="end_time font-weight-bold">
                        {{ cast[value.long_date].end_time }}
                      </span>
                    </div> -->
                    <div>
                      <span class="start_time font-weight-bold">
                        {{ cast[value.long_date].start_time }}
                      </span>
                      <div class="time_bar">▼</div>
                      <span class="end_time font-weight-bold">
                        {{ cast[value.long_date].end_time }}
                      </span>
                    </div>
                  </div>
                  <div
                    :class="
                      `sc_cell_btn d-flex justify-center align-center ` +
                      `${cast[value.long_date].cell_color}` +
                      ' ' +
                      `${cast[value.long_date].no_pattern_update}`
                    "
                  >
                    <div
                      class="sc_cell_btn_icon icon_delete pa-0"
                      @click="
                        onDeleteIcon(
                          value.long_date,
                          value.short_date,
                          'schedule_' + cast.id + '_' + value.class_date,
                          cast.id
                        )
                      "
                    >
                      <v-icon title="削除" small>mdi-trash-can</v-icon>
                    </div>
                    <div
                      class="sc_cell_btn_icon icon_copy pa-0"
                      @click="onCopyIcon(cast.id, value.long_date)"
                    >
                      <v-icon title="翌日にコピー" small
                        >mdi-arrow-right-bold</v-icon
                      >
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="date_cell d-flex justify-center align-center"
                    :schedule_id="cast[value.long_date].id"
                    @click="
                      onCell(
                        value.long_date,
                        value.short_date,
                        'schedule_' + cast.id + '_' + value.class_date,
                        cast.id
                      )
                    "
                  >
                    <span class="sp">-</span>
                  </div>
                </template>
              </div>
            </div>
            <!-- 日付情報 ここまで -->
          </template>
        </swiper-slide>
      </swiper>
    </div>
    <!-- スケジュール時刻編集用ダイアログ ここから -->
    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-truncate">{{ dialog_item.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="refForm">
            <h3>シフト時刻設定</h3>
            <v-subheader>シフト時刻を入力してください。</v-subheader>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="dialog_item.start_time"
                    :items="item_time_start"
                    no-data-text="時刻を選択"
                    label="開始時刻"
                    success="success"
                    outlined
                    clearable
                    dense
                    :rules="[rowCheck(dialog_item, 'start')]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="dialog_item.end_time"
                    :items="item_time_end"
                    no-data-text="時刻を選択"
                    label="修了時刻"
                    success="success"
                    outlined
                    clearable
                    dense
                    :rules="[rowCheck(dialog_item, 'end')]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-checkbox
              v-model="dialog_item.is_pattern_update"
              label="このシフトは、週間パターンのAUTO設定で上書きしない"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-text>
          <h3>かんたん設定</h3>
          <v-subheader
            >パターン設定のシフト、又は、1週間以内のシフトから時刻をコピーします</v-subheader
          >
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex align-center">
                <v-select
                  v-model="dialog_item.cast_hidtory_value"
                  :items="dialog_item.cast_hidtory"
                  no-data-text="--"
                  label="時刻コピー"
                  success="success"
                  outlined
                  clearable
                  dense
                  hide-details
                  @change="onHistoryChange"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="12" sm="4" order="3" order-sm="1">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="onCancel"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="12" sm="4" order="2" order-sm="2">
                <general-button
                  btn_type="error"
                  btn_block
                  @click-event="onDelete"
                  ><template v-slot:icon
                    ><v-icon left>mdi-trash-can</v-icon></template
                  >シフト削除</general-button
                >
              </v-col>
              <v-col cols="12" sm="4" order="1" order-sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- スケジュール時刻編集用ダイアログ ここまで -->
    <!-- スケジュールパターン用 ここから -->
    <schedule-pattern-component
      :p_cast_id="p_cast_id"
      :p_cast_name="p_cast_name"
      :p_cast_week_schedule="p_cast_week_schedule"
      :schedule__is_auto="s_is_auto"
      @close="closePatternDialog"
      @save-close="saveClosePatternDialog"
      @save-pattern-schedule="savePatternSchedule"
    ></schedule-pattern-component>
    <!-- スケジュールパターン用 ここまで -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  watch,
} from "@vue/composition-api";
import store from "@/store/index.js";
import _ from "lodash";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import SchedulePatternComponent from "./schedulePattern.vue";
import shops from "@/store/modules/shops";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    SchedulePatternComponent,
  },
  directives: {
    swiper: directive,
  },
  setup(props, ctx) {
    const CastsRepository = repositoryFactory.get("casts");
    const SchedulesRepository = repositoryFactory.get("schedules");
    const refForm = ref();
    const state = reactive({
      shop_dateline: setting.default_dateline,
      casts: [],
      week: {},
      table_width: 1200,
      // swiper用設定
      swiperOptions: {
        slidesPerView: setting.schedule_0_480_show_days,
        breakpoints: {
          480: {
            slidesPerView: setting.schedule_480_600_show_days,
          },
          600: {
            slidesPerView: setting.schedule_600_960_show_days,
          },
          960: {
            slidesPerView: setting.schedule_960_1600_show_days,
          },
          1600: {
            slidesPerView: setting.schedule_1600_show_days,
          },
        },
      },
      // ダイアログ表示
      dialog: false,
      dialog_item: {
        title: "",
        schedule_id: 0,
        cast_id: null,
        work_date: null,
        start_time: null,
        end_time: null,
        cell_class: null,
        target: null,
        is_pattern_update: true,
        cast_hidtory_value: null,
        cast_hidtory: [],
      },
      item_time_start: [],
      item_time_end: [],
      // パターン設定ダイアログ用
      p_cast_id: 0,
      p_cast_name: "",
      p_cast_week_schedule: [],
      s_is_auto: false,
      casts_schedule__is_auto: {},
      // 週移動ボタン
      weekBtn: {},
      // エステ魂スケジュールフラグ
      isEsutamaComment: false,
      isEsutamaButton: false,
      esutamaTimer: null,
    });

    // ダイアログ初期化
    const clear_dialog = () => {
      // 項目クリア
      state.dialog = false;
      state.dialog_item.title = "";
      state.dialog_item.schedule_id = 0;
      state.dialog_item.cast_id = null;
      state.dialog_item.work_date = null;
      state.dialog_item.start_time = null;
      state.dialog_item.end_time = null;
      state.dialog_item.cell_class = null;
      state.dialog_item.is_pattern_update = true;
      state.dialog_item.cast_hidtory_value = null;
      set(state.dialog_item, "cast_hidtory", []);
    };

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      await store.dispatch("loadingIcon/showIcon");

      // エステ魂のスケジュールが有効か判定する
      state.isEsutamaButton = false; // ボタンを表示する場合、trueにする
      state.isEsutamaComment = false; // エステ魂のコメントを表示する場合、trueにする
      state.esutamaTimer = null;
      const shopSites = store.getters["shop_sites/getShopSiteList"];
      for (let i = 0; i < shopSites.length; i++) {
        if (setting.esutama_site_id.includes(Number(shopSites[i].sites_id))) {
          state.isEsutamaButton = true;
          break;
        }
      }
      const shopData = store.getters["shops/currentShop"].data;
      if (state.isEsutamaButton) {
        // エステ魂のスケジュール設定があるか確認する(店舗項目:"esutamaschedule")
        if (shopData.prop) {
          for (let j = 0; j < shopData.prop.length; j++) {
            if (shopData.prop[j].key == "esutamaschedule") {
              state.esutamaTimer = shopData.prop[j].value;
              break;
            }
          }
        }
        if (!state.esutamaTimer) {
          state.isEsutamaComment = true;
        }
      }

      // 日付選択用
      state.shop_dateline = store.getters["shops/dateline"];
      state.item_time_start = utilDate.getScheduleTimeSelectStart(
        state.shop_dateline
      );
      state.item_time_end = utilDate.getScheduleTimeSelectEnd(
        state.shop_dateline
      );

      // 今日から２週間分の日付を取得
      const current_day = await store.getters["schedules/getCurrentDay"];
      let today = utilDate.getToday(current_day, state.shop_dateline);
      let loop_day = utilDate.getToday(current_day, state.shop_dateline);
      for (let i = 0; i < setting.schedule_days; i++) {
        const date_format = {
          class_date: utilDate.getDateFormatNum(loop_day),
          short_date: utilDate.getDateFormatShortDisplay(loop_day), // 画面表示用
          long_date: utilDate.getDateFormatLong(loop_day), // データ登録用
          work_num: 0, // 一日の出勤人数
        };
        state.week[i] = date_format;
        loop_day.setDate(loop_day.getDate() + 1);
      }

      // システム内の登録キャストを取得する
      const shop_id = await store.getters["shops/currentShop"].id;
      const params = {
        shop_id: shop_id,
        start_date: utilDate.getDateFormatLong(today),
        end_date: utilDate.getDateFormatLong(loop_day),
      };
      await CastsRepository.list_with_schedule(params)
        .then((response) => {
          if (response.data) {
            // データ格納
            state.casts = [];
            Object.keys(response.data).forEach(function (key) {
              state.casts.push({
                id: response.data[key].id,
                shop_id: response.data[key].shop_id,
                name: response.data[key].name,
                image: response.data[key].image,
                prop: response.data[key].prop,
                schedule_list: response.data[key].schedule_list,
                selected_datetime_number: utilDate.getDatetimeFormatNum(
                  new Date()
                ),
                is_enable: response.data[key].is_enable,
              });
            });
            // console.log(state.casts);
            // データ設定 キャストひとりひとり順次読み込む
            Object.keys(state.casts).forEach(function (key) {
              // スケジュール設定
              const schedule = state.casts[key].schedule_list;
              // 画面に表示する日付分ループする
              Object.keys(state.week).forEach(function (wkey) {
                // スケジュール画面の背景色をセット
                let backcolor = "backcolor_weekday";
                let d = new Date(state.week[wkey].long_date);
                if (utilDate.getDateWeekday(d) == 0) {
                  backcolor = "backcolor_sunday";
                } else if (utilDate.getDateWeekday(d) == 6) {
                  backcolor = "backcolor_saturday";
                }
                // セルの初期値をセット
                const cell_item = {
                  "ref_key": 0,
                  "id": null,
                  "shop_id": shop_id,
                  "cast_id": state.casts[key].id,
                  "work_date": state.week[wkey].long_date,
                  "start_time": null,
                  "end_time": null,
                  "config": null,
                  "no_pattern_update": "pattern_update",
                  "cell_color": backcolor,
                  "prop": [],
                };
                // 取得したスケジュールがあれば、配列に入れる。
                Object.keys(schedule).forEach(function (skey) {
                  // 該当日にスケジュールがあるか
                  if (schedule[skey].work_date == state.week[wkey].long_date) {
                    // スケジュールがあった場合
                    cell_item.id = schedule[skey].id;
                    cell_item.start_time = schedule[skey].start_time;
                    cell_item.end_time = schedule[skey].end_time;
                    cell_item.config = schedule[skey].config;
                    cell_item.prop = schedule[skey].prop;
                    // 週間パターンで上書きしないフラグの確認
                    if (cell_item.config != null && cell_item.config != "") {
                      if (
                        JSON.parse(cell_item.config).is_pattern_update == "true"
                      ) {
                        cell_item.no_pattern_update = "no_pattern_update";
                      }
                    }
                    // 該当日の出勤人数に加算
                    if (
                      schedule[skey].start_time == null &&
                      schedule[skey].end_time == null
                    ) {
                      // 休みの場合は加算しない
                    } else {
                      state.week[wkey].work_num++;
                    }
                  }
                });

                // スケジュール表示用の配列にセット
                set(state.casts[key], state.week[wkey].long_date, cell_item);
              });

              // スケジュールパターンAUTO設定
              state.casts[key]["schedule__is_auto"] = false;
              state.casts_schedule__is_auto[state.casts[key].id] = false;
              const prop = state.casts[key].prop;
              Object.keys(prop).forEach(function (pkey) {
                if (prop[pkey].key == "schedule__is_auto") {
                  state.casts[key]["schedule__is_auto"] =
                    prop[pkey].value == "true" ? true : false;
                  set(
                    state.casts_schedule__is_auto,
                    state.casts[key].id,
                    prop[pkey].value == "true" ? true : false
                  );
                }
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:Schedule/ScheduleList/index.vue/init CastsRepository.list_with_schedule (" +
            error +
            ")"
          );
        });

      // ダイアログ初期化
      clear_dialog();

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 初期化する
    state.weekBtn = store.getters["schedules/getWeekBtn"];
    init();

    // 保存処理
    const save = async () => {
      // 週間パターンで上書きするかどうかフラグ
      const schedule_config = JSON.stringify({
        "is_pattern_update": state.dialog_item.is_pattern_update
          ? "true"
          : "false",
      });
      // 新規か更新か
      if (state.dialog_item.schedule_id == 0) {
        // 新規追加
        const params = {
          shop_id: store.getters["shops/currentShop"].id,
          cast_id: state.dialog_item.cast_id,
          work_date: state.dialog_item.work_date,
          start_time:
            state.dialog_item.start_time == null
              ? ""
              : state.dialog_item.start_time,
          end_time:
            state.dialog_item.end_time == null
              ? ""
              : state.dialog_item.end_time,
          config: schedule_config,
        };
        // データ追加処理
        await SchedulesRepository.create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              state.dialog_item.schedule_id = response.data.id;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:Schedule/ScheduleList/index.vue/save SchedulesRepository.create (" +
              error +
              ")"
            );
          });
      } else {
        // 更新(start_timeとend_timeが無い時は空文字を送る)
        const params = {
          id: state.dialog_item.schedule_id,
          shop_id: store.getters["shops/currentShop"].id,
          cast_id: state.dialog_item.cast_id,
          work_date: state.dialog_item.work_date,
          start_time:
            state.dialog_item.start_time == null
              ? ""
              : state.dialog_item.start_time,
          end_time:
            state.dialog_item.end_time == null
              ? ""
              : state.dialog_item.end_time,
          config: schedule_config,
        };
        await SchedulesRepository.update(params)
          .then((response) => {
            if (response.data) {
              // データ更新OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:Schedule/ScheduleList/index.vue/save SchedulesRepository.update (" +
              error +
              ")"
            );
          });
      }
      // スケジュールのパネルを更新
      await refreshSheet(schedule_config);
    };

    const refreshSheet = async (schedule_config) => {
      // 配列に更新内容を反映
      const refreshFlg = Object.keys(state.casts).forEach(function (key) {
        // 該当キャストの該当日の配列を変えておく→リアクティブで画面に反映される
        if (state.casts[key].id == state.dialog_item.cast_id) {
          // state.casts[key]にstate.dialog_item.work_dateが存在するかチェック
          if (!(state.dialog_item.work_date in state.casts[key])) {
            // ない場合は抜ける
            console.log("work_date not found", state.dialog_item);
            return false;
          }

          set(
            state.casts[key][state.dialog_item.work_date],
            "schedule_id",
            state.dialog_item.schedule_id
          );
          set(
            state.casts[key][state.dialog_item.work_date],
            "start_time",
            state.dialog_item.start_time
          );
          set(
            state.casts[key][state.dialog_item.work_date],
            "end_time",
            state.dialog_item.end_time
          );
          set(
            state.casts[key][state.dialog_item.work_date],
            "config",
            schedule_config
          );
          set(
            state.casts[key][state.dialog_item.work_date],
            "no_pattern_update",
            state.dialog_item.is_pattern_update
              ? "no_pattern_update"
              : "pattern_update"
          );
          // ループ抜ける
          return true;
        }
      });

      if (!refreshFlg) {
        return;
      }

      // 表の上に表示している出勤人数を再計算
      let num = 0;
      Object.keys(state.casts).forEach(function (key) {
        if (
          state.casts[key][state.dialog_item.work_date].start_time != null ||
          state.casts[key][state.dialog_item.work_date].start_time != null
        ) {
          num++;
        }
      });
      Object.keys(state.week).forEach(function (key) {
        if (state.week[key].long_date == state.dialog_item.work_date) {
          set(state.week[key], "work_num", num);
        }
      });
    };

    // スケジュールのパネルをクリックした時
    const onCell = async (long_date, short_date, cell_class, cast_id) => {
      // クリックしたセルの情報をstate.dialog_itemに移す
      Object.keys(state.casts).forEach(function (key) {
        // 該当のキャスト
        if (state.casts[key].id == cast_id) {
          state.dialog_item.title = short_date + " " + state.casts[key].name;
          state.dialog_item.cast_id = cast_id;
          state.dialog_item.work_date = long_date;
          state.dialog_item.cell_class = cell_class;
          state.dialog_item.schedule_id = state.casts[key][long_date].id ?? 0;
          state.dialog_item.start_time = state.casts[key][long_date].start_time;
          state.dialog_item.end_time = state.casts[key][long_date].end_time;
          state.dialog_item.is_pattern_update =
            state.casts[key][long_date].no_pattern_update == "no_pattern_update"
              ? true
              : false;
        }
      });
      // 休日の場合、「このシフトは、週間パターンのAUTO設定を上書きしない」にチェックをいれておく
      if (
        state.dialog_item.start_time == null &&
        state.dialog_item.end_time == null
      ) {
        state.dialog_item.is_pattern_update = true;
      }

      // 該当キャストの過去スケジュールパターンを取得
      const param = {
        shop_id: store.getters["shops/currentShop"].id,
        cast_id: cast_id,
        work_date: long_date,
      };
      const result = await SchedulesRepository.cast_history(param)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data;
          }
        })
        .catch((error) => {
          throw "ERROR:SchedulesRepository.cast_history (" + error + ")";
        });
      if (result) {
        const tmpData = [];
        for (let i = 0; i < result.length; i++) {
          tmpData.push({
            text: result[i].start_time + " - " + result[i].end_time,
            value: result[i].start_time + "-" + result[i].end_time,
          });
        }
        set(state.dialog_item, "cast_hidtory", tmpData);
      } else {
        set(state.dialog_item, "cast_hidtory", []);
      }

      state.dialog = true;
    };

    // ダイアログの保存
    const onSubmit = async () => {
      // 入力チェック
      if (!refForm.value.validate()) {
        // エラー処理
      } else {
        // 保存処理
        // ローディング表示
        store.dispatch("loadingIcon/showIcon");

        // データ更新
        await save();

        // ダイアログ初期化
        clear_dialog();

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");
      }
    };

    // パターンダイアログを開く
    const showPatternDialog = (index) => {
      // あらかじめ現スケジュール（一週間分）を渡す
      const schedule_week = [];
      let w = 0;
      while (w < 7) {
        const d = new Date(state.week[w].long_date);
        const tmp = state.casts[index][state.week[w].long_date];
        tmp["week_num"] = d.getDay();
        schedule_week.push(tmp);
        w++;
      }
      state.p_cast_week_schedule = schedule_week;
      // キャスト名
      state.p_cast_name = state.casts[index].name;
      // パターンAUTO設定
      state.s_is_auto = state.casts_schedule__is_auto[state.casts[index].id];
      // キャストID（p_cast_idに値をセットするとダイアログが開く）
      state.p_cast_id = state.casts[index].id;
    };

    // パターンダイアログを閉じる
    const closePatternDialog = () => {
      state.p_cast_id = 0;
    };

    // パターンダイアログを閉じる(保存時)
    const saveClosePatternDialog = (cast_id, is_auto) => {
      set(state.casts_schedule__is_auto, cast_id, is_auto);
      state.p_cast_id = 0;
    };

    // パターンをスケジュールに反映する処理（SchedulePatternComponentから呼ばれる）
    const savePatternSchedule = () => {
      state.p_cast_id = 0;
      init(); // 画面を更新
    };

    // ダイアログのキャンセル
    const onCancel = () => {
      state.dialog = false;
      // ダイアログ初期化
      clear_dialog();
    };

    // シフト削除
    const onDelete = async () => {
      // 削除処理 (空データで保存する)
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // データ更新
      state.dialog_item.start_time = null;
      state.dialog_item.end_time = null;
      state.dialog_item.is_pattern_update = false;

      await save();

      // ダイアログ初期化
      clear_dialog();

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 先週ボタン
    const onPrev = () => {
      let current_day = store.getters["schedules/getCurrentDay"];
      if (current_day >= setting.schedule_prev_next_days) {
        current_day = current_day - setting.schedule_prev_next_days;
        store.dispatch("schedules/setCurrentDay", current_day);
      }
      state.weekBtn = store.getters["schedules/getWeekBtn"];
      init();
    };
    // 本日ボタン
    const onToday = () => {
      store.dispatch("schedules/setCurrentDay", 0);
      state.weekBtn = store.getters["schedules/getWeekBtn"];
      init();
    };
    // 次週ボタン
    const onNext = () => {
      let current_day = store.getters["schedules/getCurrentDay"];
      current_day = current_day + setting.schedule_prev_next_days;
      store.dispatch("schedules/setCurrentDay", current_day);
      state.weekBtn = store.getters["schedules/getWeekBtn"];
      init();
    };

    // // dialog_itemの値が変更されたとき、値をチェック
    // watch(
    //   () => _.cloneDeep(state.dialog_item),
    //   () => {
    //     if (refForm.value === undefined) {
    //       return true;
    //     }
    //     return refForm.value.validate();
    //   }
    // );

    // 入力された値のチェック
    const rowCheck = (item, itemName) => {
      let result = true;
      // 両方空ならOK
      if (item.start_time == null && item.end_time == null) {
        return result;
      }
      // 片方空はエラー
      switch (itemName) {
        case "start":
          if (item.start_time == null && item.end_time != null) {
            result = "必須入力項目です";
          }
          break;
        case "end":
          if (item.end_time == null && item.start_time != null) {
            result = "必須入力項目です";
          }
          break;
        default:
      }
      // 開始と終了の時刻が逆ならエラー
      if (item.start_time != null && item.end_time != null) {
        if (
          !utilDate.checkScheduleTime(
            item.start_time,
            item.end_time,
            state.shop_dateline
          )
        ) {
          result = "時刻の前後が正しくありません";
        }
      }

      return result;
    };

    const onDeleteIcon = async (long_date, short_date, cell_class, cast_id) => {
      // クリックしたセルの情報をstate.dialog_itemに移す
      Object.keys(state.casts).forEach(function (key) {
        // 該当のキャスト
        if (state.casts[key].id == cast_id) {
          state.dialog_item.title = short_date + " " + state.casts[key].name;
          state.dialog_item.cast_id = cast_id;
          state.dialog_item.work_date = long_date;
          state.dialog_item.cell_class = cell_class;
          state.dialog_item.schedule_id = state.casts[key][long_date].id ?? 0;
          state.dialog_item.start_time = state.casts[key][long_date].start_time;
          state.dialog_item.end_time = state.casts[key][long_date].end_time;

          onDelete();
        }
      });
    };

    // 翌日コピー
    const onCopyIcon = async (cast_id, copy_date) => {
      store.dispatch("loadingIcon/showIcon");
      // 翌日コピー(当日を渡す)
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        cast_id: cast_id,
        copy_date: copy_date,
      };
      const result = await SchedulesRepository.copy_t(params)
        .then((response) => {
          if (response.data) {
            // データ更新OK
            return response.data;
          }
        })
        .catch((error) => {
          throw "ERROR:SchedulesRepository.copy_t (" + error + ")";
        });
      store.dispatch("loadingIcon/hideIcon");

      if (result) {
        if (result.copy_schedule !== null) {
          state.dialog_item.cast_id = result.copy_schedule.cast_id;
          state.dialog_item.work_date = result.copy_schedule.work_date;
          state.dialog_item.schedule_id = result.copy_schedule.schedule_id;
          state.dialog_item.start_time = result.copy_schedule.start_time;
          state.dialog_item.end_time = result.copy_schedule.end_time;
          // 週間パターンで上書きしないフラグの確認
          state.dialog_item.is_pattern_update = false;
          if (
            result.copy_schedule.config != null &&
            result.copy_schedule.config != ""
          ) {
            if (
              JSON.parse(result.copy_schedule.config).is_pattern_update ==
              "true"
            ) {
              state.dialog_item.is_pattern_update = true;
            }
          }

          // データ更新
          await refreshSheet(result.copy_schedule.config);
        }
        // ダイアログ初期化
        clear_dialog();
      }
    };

    const onHistoryChange = (value) => {
      if (value == null) {
        return;
      }
      const tmp = value.split("-");
      state.dialog_item.start_time = tmp[0];
      state.dialog_item.end_time = tmp[1];
    };

    return {
      setting,
      refForm,
      ...toRefs(state),
      onCell,
      onSubmit,
      onCancel,
      onDelete,
      onPrev,
      onToday,
      onNext,
      showPatternDialog,
      closePatternDialog,
      saveClosePatternDialog,
      savePatternSchedule,
      rowCheck,
      onCopyIcon,
      onDeleteIcon,
      onHistoryChange,
    };
  },
});
</script>

<style scoped>
/* スケジュールテーブルのサイズ指定（キャスト列、日付列） */
.cast_area {
  width: 12%;
  float: left;
}
.swiper_area {
  width: 88%;
  float: left;
}
@media screen and (max-width: 480px) {
  .cast_area {
    width: 40%;
  }
  .swiper_area {
    width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 960px) {
  .cast_area {
    width: 25%;
  }
  .swiper_area {
    width: 75%;
  }
}

/* ラベル */
@media screen and (max-width: 480px) {
  .sp_schedule_label {
    font-size: 0.6em;
    display: flex;
  }
  .pc_schedule_label {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 1600px) {
  .sp_schedule_label {
    display: none;
  }
  .pc_schedule_label {
    display: flex;
    font-size: 0.85em;
  }
}

@media screen and (min-width: 1600px) {
  .sp_schedule_label {
    display: none;
  }
  .pc_schedule_label {
    display: none;
  }
}

/* テーブル関連 */
.center_item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.schedule_table_upper_header {
  box-sizing: border-box;
  text-align: center;
  height: 1.25rem;
  font-size: 0.75em;
  font-weight: normal;
  background-color: #6495ed;
  color: white;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.schedule_table_header {
  box-sizing: border-box;
  text-align: center;
  height: 3em;
  background-color: #1976d2;
  color: white;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.cast_name {
  box-sizing: border-box;
  height: 64px;
  padding: 0.25em;
  border-right: 1px solid #eee;
}
.cast_name_bottom {
  box-sizing: border-box;
  height: 30px;
  padding-right: 4px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #1976d2;
}
.cast_name_bottom:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.cast_name:last-child {
  border-right: none;
}
.cast_name_box {
  position: relative;
}
.cast_name_label {
  position: absolute;
  bottom: 12px;
  left: 0;
  padding-left: 60px;
  width: 100%;
  font-size: 0.8em;
}
.cast_name_button {
  position: absolute;
  top: 0;
  right: 0;
}
.cast_auto_label {
  /* position: absolute; */
  color: #1976d2;
  font-size: 0.7em;
  font-weight: bold;
  /* top: 8px;
  left: 0; */
  /* padding-left: 60px; */
  width: 100%;
  /* line-height: 1; */
}
.cast_auto_label_disable {
  font-weight: normal;
  color: #bbb;
}
.sc_cell {
  box-sizing: border-box;
  text-align: center;
  height: 94px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid steelblue;
  line-height: 1.2;
}
.time_bar {
  margin-top: 6px;
  margin-bottom: 6px;
  height: 0.5em;
  font-size: 6px;
  color: #1976d2;
}

.backcolor_saturday {
  background-color: #d1edff;
}
.backcolor_sunday {
  background-color: #ffe3e7;
}

.cast_name:hover {
  background-color: aliceblue;
}
.date_cell {
  width: 100%;
  height: 100%;
  padding: 0.25em;
  font-size: 1em;
  color: #1976d2;
}
.date_cell:hover {
  background-color: aliceblue;
  cursor: pointer;
}
/* .sc_cell:hover {
  background-color: aliceblue;
  cursor: pointer;
} */
.sc_cell_btn {
  height: 26px;
  width: 100%;
}
/* .sc_cell_btn:hover {
  background-color: aliceblue;
  cursor: pointer;
} */
.sc_cell_btn_icon {
  height: 26px;
  width: 50%;
  text-align: center;
}
.sc_cell_btn_icon.icon_copy:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.sc_cell_btn_icon.icon_delete:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.start_time,
.end_time,
.sp {
  display: inline-block;
}

.no_pattern_update {
  background-color: lemonchiffon;
}

.backcolor_gray {
  background-color: #999;
}

.icon-description-container {
  overflow-x: auto;
}
.nowrap {
  white-space: nowrap;
}
</style>
