<template>
  <div>
    <!-- 週間パターン登録ダイアログ -->
    <v-dialog v-model="dialog" scrollable persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">週間パターン [ {{ cast_name }} ]</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancelDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="refForm">
            <h3>パターン設定</h3>
            <div class="mt-2 mb-2">
              一週間のパターンを設定してください。<v-btn
                text
                color="primary"
                class="font-weight-bold pa-1 d-inline"
                :loading="scheduleToPatternLoading"
                :disabled="scheduleToPatternLoading"
                @click="scheduleToPattern()"
                ><span v-html="scheduleToPatternButtonText"
              /></v-btn>
            </div>
            <div
              v-for="(day, index) in week"
              :key="`${index} + '_' + ${new Date()}`"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" sm="2">
                    <span class="font-weight-bold float-sm-right">{{
                      day.name
                    }}</span></v-col
                  >
                  <v-col cols="6" sm="5">
                    <v-select
                      v-model="week_item[index].start_time"
                      :items="item_time_start"
                      no-data-text="時刻を選択"
                      label="開始時刻"
                      success="success"
                      outlined
                      clearable
                      dense
                      :rules="[rowCheck(index, 'start')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="5">
                    <v-select
                      v-model="week_item[index].end_time"
                      :items="item_time_end"
                      no-data-text="時刻を選択"
                      label="修了時刻"
                      success="success"
                      outlined
                      clearable
                      dense
                      :rules="[rowCheck(index, 'end')]"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <h3>パターンをスケジュールに反映させる</h3>
            <div class="mt-2 mb-2">
              パターンに設定した内容で今日から１週間分のスケジュールを上書きします。
            </div>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <general-button
                    btn_color="info"
                    btn_block
                    @click-event="copyPattern"
                    ><template v-slot:icon
                      ><v-icon left>mdi-content-copy</v-icon></template
                    >スケジュールに反映</general-button
                  >
                </v-col>
              </v-row>
            </v-container>
            <h3>AUTO設定</h3>
            <div class="mt-2 mb-2">
              ONにするとパターンに設定した内容で毎日スケジュールを上書きします。スケジュールを手入力で運用する場合は、OFFにして下さい。
            </div>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="is_auto"
                    :label="`${is_auto ? 'ON' : 'OFF'}`"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="6">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="6">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >パターン保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  watch,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import _ from "lodash";
import store from "@/store/index.js";
import utilDate from "@/common/utilDate.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";

export default defineComponent({
  props: {
    // キャストID
    p_cast_id: {
      default: 0,
    },
    // キャスト名
    p_cast_name: {
      default: 0,
    },
    // 日曜から土曜までの現スケジュール（パターンコピー処理用）
    p_cast_week_schedule: {
      default: [],
    },
    // スケジュールAUTO設定フラグ
    schedule__is_auto: {
      default: false,
    },
  },
  setup(props, context) {
    const SchedulePatternsRepository =
      repositoryFactory.get("schedulePatterns");
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refForm = ref();
    const p = toRefs(props);
    const week = [
      { id: 0, name: "日曜日" },
      { id: 1, name: "月曜日" },
      { id: 2, name: "火曜日" },
      { id: 3, name: "水曜日" },
      { id: 4, name: "木曜日" },
      { id: 5, name: "金曜日" },
      { id: 6, name: "土曜日" },
    ];
    const state = reactive({
      shop_dateline: setting.default_dateline,
      dialog: false,
      item_time_start: [],
      item_time_end: [],
      week_item: [],
      is_auto: false,
      cast_name: "",
      scheduleToPatternLoading: false,
      scheduleToPatternButtonText: "スケジュールから入力",
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // 日付選択用
      state.shop_dateline = store.getters["shops/dateline"];
      state.item_time_start = utilDate.getScheduleTimeSelectStart(
        state.shop_dateline
      );
      state.item_time_end = utilDate.getScheduleTimeSelectEnd(
        state.shop_dateline
      );

      // 自動フラグ
      state.is_auto = p.schedule__is_auto.value;

      // キャスト名保持
      state.cast_name = p.p_cast_name.value;

      // パターン設定 初期化
      const shop_id = store.getters["shops/currentShop"].id;
      state.week_item = [];
      for (const day of week) {
        state.week_item.push({
          id: 0,
          shop_id: shop_id,
          cast_id: p.p_cast_id.value,
          week: day.id,
          start_time: null,
          end_time: null,
          config: null,
        });
      }

      if (p.p_cast_id.value != 0) {
        // スケジュールパターン取得
        await SchedulePatternsRepository.cast_pattern(p.p_cast_id.value)
          .then((response) => {
            if (response.data.length != 0) {
              // データ追加OK
              state.week_item = [];
              Object.keys(response.data).forEach(function (key) {
                state.week_item.push({
                  id: response.data[key].id,
                  shop_id: shop_id,
                  cast_id: p.p_cast_id.value,
                  week: response.data[key].week,
                  start_time: response.data[key].start_time,
                  end_time: response.data[key].end_time,
                  config:
                    response.data[key].config != null &&
                    response.data[key].config != ""
                      ? JSON.parse(response.data[key].config).memo
                      : "",
                });
              });
            }
          })
          .catch((error) => {
            throw (
              "ERROR:SchedulePattern.vue/onSubmit SchedulePatternsRepository.cast_pattern (" +
              error +
              ")"
            );
          });
      }

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    // 保存処理
    const save = async () => {
      const cast_id = p.p_cast_id.value;
      // スケジュールのパターンを登録(値がない場合、Nullではなく空文字にする)
      let patterns = state.week_item;
      Object.keys(patterns).forEach(function (key) {
        if (patterns[key].start_time == null) {
          patterns[key].start_time = "";
        }
        if (patterns[key].end_time == null) {
          patterns[key].end_time = "";
        }
      });
      const params = {
        cast_id: cast_id,
        pattern: patterns,
      };
      // データ追加処理
      await SchedulePatternsRepository.cast_pattern_save(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:SchedulePattern.vue/onSubmit SchedulePatternsRepository.cast_pattern_save (" +
            error +
            ")"
          );
        });

      // 自動更新フラグ保存(save_allはpropの配列で受け取るため、1件でも配列で渡す)
      const prop = {
        cast_id: cast_id,
        key: "schedule__is_auto",
        value: state.is_auto ? "true" : "false",
      };
      await CastPropsRepository.save_all({ cast_prop: [prop] })
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:SchedulePattern.vue/onSubmit SchedulePatternsRepository.save_all (" +
            error +
            ")"
          );
        });
    };

    // キャストID監視（0の時、ダイアログを閉じる。0以外の時、ダイアログを開く。）
    watch(p.p_cast_id, () => {
      if (p.p_cast_id.value != 0) {
        init();
        state.dialog = true;
      } else {
        state.dialog = false;
      }
    });

    // ダイアログのキャンセル
    const cancelDialog = () => {
      state.dialog = false;
      context.emit("close");
    };

    // ダイアログの保存
    const onSubmit = async () => {
      // 入力チェック
      if (!refForm.value.validate()) {
        // エラー処理
        alert("入力に誤りがありました。赤色の項目を確認してください。");
      } else {
        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }

        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        await save(); // 保存処理
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示

        state.dialog = false;
        context.emit("save-close", p.p_cast_id.value, state.is_auto);
      }
    };

    // パターンのコピー（パターン→スケジュール）
    const copyPattern = async () => {
      // 先に保存処理を実行する
      if (
        !window.confirm(
          "設定したパターンで本日から１週間後までのスケジュールを上書きします。\nよろしいですか？"
        )
      ) {
        return false;
      }
      // 入力チェック
      if (!refForm.value.validate()) {
        // エラー処理
        alert("コピー内容に誤りがありました。赤色の項目を確認してください。");
      } else {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        await save(); // 保存処理

        // スケジュールに上書き
        // const shop_dateline = store.getters[("shops/getProp", "dateline")];
        const shop_dateline = store.getters["shops/dateline"];
        const params = {
          cast_id: p.p_cast_id.value,
          today: utilDate.getDateFormatLong(
            utilDate.getToday(0, shop_dateline)
          ),
        };
        await SchedulePatternsRepository.apply_pattern(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:SchedulePattern.vue/onSubmit SchedulePatternsRepository.apply_pattern (" +
              error +
              ")"
            );
          });

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示

        state.dialog = false;
        context.emit("save-pattern-schedule");
      }
    };

    // 現スケジュールからパターンにコピー（スケジュール→パターン）
    const scheduleToPattern = () => {
      // 処理中は、ボタン・カードをdisabledに。
      state.scheduleToPatternLoading = true;
      state.scheduleToPatternButtonText = "取得中";

      // パターンコピー処理
      let w = 0;
      while (w < 7) {
        const schedule = p.p_cast_week_schedule.value[w];
        const week_num = schedule["week_num"];
        set(
          state.week_item[week_num],
          "start_time",
          p.p_cast_week_schedule.value[w]["start_time"]
        );
        set(
          state.week_item[week_num],
          "end_time",
          p.p_cast_week_schedule.value[w]["end_time"]
        );
        w++;
      }

      // コピーOK
      state.scheduleToPatternButtonText =
        "<span style='color:blue'>コピーしました</span>";
      setTimeout(() => {
        state.scheduleToPatternButtonText = "スケジュールから入力";
      }, 5000);

      // 結果
      state.scheduleToPatternLoading = false;
    };

    // // dialog_itemの値が変更されたとき、値をチェック
    // watch(
    //   () => _.cloneDeep(state.week_item),
    //   () => {
    //     if (refForm.value === undefined) {
    //       return true;
    //     }
    //     return refForm.value.validate();
    //   }
    // );

    // 入力された値のチェック
    const rowCheck = (itemIndex, itemName) => {
      const item = state.week_item[itemIndex];
      let result = true;
      // 両方空ならOK
      if (item.start_time == null && item.end_time == null) {
        return result;
      }
      // 片方空はエラー
      switch (itemName) {
        case "start":
          if (item.start_time == null && item.end_time != null) {
            result = "必須入力項目です";
          }
          break;
        case "end":
          if (item.end_time == null && item.start_time != null) {
            result = "必須入力項目です";
          }
          break;
        default:
      }
      // 開始と終了の時刻が逆ならエラー
      if (item.start_time != null && item.end_time != null) {
        if (
          !utilDate.checkScheduleTime(
            item.start_time,
            item.end_time,
            state.shop_dateline
          )
        ) {
          result = "時刻の前後が正しくありません";
        }
      }

      return result;
    };

    return {
      setting,
      refForm,
      ...toRefs(state),
      week,
      cancelDialog,
      onSubmit,
      copyPattern,
      scheduleToPattern,
      rowCheck,
    };
  },
});
</script>

<style scoped>
.row-border {
  border-bottom: 1px solid gray;
}
.row-border:last-child {
  border-bottom: none;
}
</style>
